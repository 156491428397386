<template>
  <div :class="`base-timer ${remainingPathColor}`">
    <svg
      class="base-timer__svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g class="base-timer__circle">
        <circle
          class="base-timer__path-elapsed-bg"
          cx="50"
          cy="50"
          r="42"
        ></circle>
        <circle
          class="base-timer__path-elapsed"
          cx="50"
          cy="50"
          r="42"
        ></circle>
        <path
          :stroke-dasharray="circleDasharray"
          class="base-timer__path-remaining"
          :class="remainingPathColor"
          d="
            M 50, 50
            m -42, 0
            a 42,42 0 1,0 84,0
            a 42,42 0 1,0 -84,0
          "
        ></path>
      </g>
    </svg>
    <span class="base-timer__label">{{ formattedTimeLeft }}</span>
  </div>
</template>

<script>
//const FULL_DASH_ARRAY = 283;
const FULL_DASH_ARRAY = 264;
const WARNING_THRESHOLD = 120;
const ALERT_THRESHOLD = 60;

const COLOR_CODES = {
  info: {
    color: "defaultColor",
  },
  warning: {
    color: "orange",
    threshold: WARNING_THRESHOLD,
  },
  alert: {
    color: "red",
    threshold: ALERT_THRESHOLD,
  },
};

export default {
  data() {
    return {
      timePassed: 0,
      timerInterval: null,
      timelimit: 300,
    };
  },
  props: {
      timePassed_prop: Number,
      triggerModel: undefined
  },
  computed: {
    circleDasharray() {
      let thisVal;
      if ((this.timeFraction * FULL_DASH_ARRAY).toFixed(0) > -1) {
        thisVal = `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} ${FULL_DASH_ARRAY}`;
      } else {
        thisVal = `${`0`} ${FULL_DASH_ARRAY}`;
      }
      return thisVal;
    },

    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },

    timeLeft() {
      return this.timelimit - this.timePassed;
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.timelimit;
      return rawTimeFraction - (1 / this.timelimit) * (1 - rawTimeFraction);
    },

    remainingPathColor() {
      const { alert, warning, info } = COLOR_CODES;

      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    },
  },

  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp();
      }
    },
  },

  mounted() {
    this.timePassed = this.timePassed_prop;
    this.startTimer();
  },

  methods: {
    onTimesUp() {
      //alert("time over");
      clearInterval(this.timerInterval);
      if (this.triggerModel) {
        this.emitter.emit('timeUp');
      }
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
  },
};
</script>

<style scoped lang="scss">

$default-color:  #164194;
$warning-color: #F1A706;
$last-warining-color: red;


.base-timer {
  position: relative;
  width: 100%;
  height: 100%;
  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed-bg {
    stroke-width: 14px;
    stroke: $default-color;
    margin: 10px;
  }

  &__path-elapsed {
    stroke-width: 11px;
    stroke: #fff;
  }

  &__path-remaining {
    stroke-width: 12px;
    stroke-linecap: butt;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.defaultColor {
      color: $default-color;
    }

    &.orange {
      color: $warning-color;
    }

    &.red {
      color: $last-warining-color;
    }
  }

  &__label {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
  }
}

  .base-timer.red {
    .base-timer__path-elapsed-bg {
      stroke:$last-warining-color;
    }
    .base-timer__label {
      color:$last-warining-color;
    }

  }
</style>
