<template>
 <Header 
    :logo_link="`/startpage`"
    :home_link="`/startpage`"
    :lang="`EN`"
    :show_home="false"
    :show_faqs="false"
    :show_manage="false"
    :show_languages="false"
    :show_basket="false"
    :show_login="false"
    :show_signup="false"
  ></Header>
  <div class="page-bg covid-bg">

    <Basket
      :passedclass="``"
      :positionTop="`24px`"
      :position="`fixed`"
      :showButton="true"
      :lang="`EN`"
      :fmmtransobjpassed="fmmtransobj"
      :key="basketKey"
      :hasItems="true"
      :totalfee="totalfee"
      :bookingfee="bookingfee"
    ></Basket>

    <div class="welcome-text covid-bg-mobile">
      <h1>Book a Covid-19 Test</h1>
      <h2>It's good to see you today! Ready to book an appointment?</h2>
    </div>

    <div class="welcome-widget">
       <form @submit.prevent="submit">
        <div class="inner-widget-notice">
          <h1>Book your COVID-19 test</h1>
          <p>
          All PCR tests are carried out onsite at the test site by the customer and then analysed at the Randox Health laboratory.
          <br>
          There is no clinician available onsite. The test kit will require you to complete a nasal and throat swab and the process should take no more than 5 minutes.
          </p>
        </div>
      <div class="inner">
        <div v-if="show_venue" class="input-wrap" :class="{ 'form-group--error': $v.venue.$error }" style="min-width: 25%;"> 
          <div class="input">
            <label for="select-venue">Select Venue</label>
            <select  v-model="venue" name="venue" id="select-venue" :value="`Add Venue`" @change="setwidget">
              <option v-for="item in venues" :key="item.venue_id" :value="item.venue_id">{{item.name}}</option>
            </select>
            <!-- <i class="fa fa-plus" aria-hidden="true"></i> -->
             <i class="fas fa-chevron-down"></i>
          </div>
        </div>
        <div class="input-wrap" :class="{ 'form-group--error': $v.date_value.$error }">
          <div class="input">
            <label for="add-date">Select a date</label>
            <Calendar 
              readonly
              id="add-date"
              v-model="date_value"
              :minDate="min_date_value"
              
              :manualInput="false" 
              :touchUI="touchUI" 
              :dateFormat="`D d'${dateFormatter}' M y`" 
              placeholder=""/>
            <!-- <i class="fa fa-plus" aria-hidden="true"></i> -->
             <i class="fas fa-chevron-down"></i>
          </div>
        </div>
        <div class="input-wrap" :class="{ 'form-group--error': $v.adults.$error }"> 
          <div class="input">
            <label for="select-adults">Adults</label>
            <select v-model="adults" name="adults" id="select-adults" :required="true" @change="setchildren">
              <option v-for="n in parseInt(adult_options)" :key="n" :value="n" >{{n}}</option>
            </select>
           <i class="fas fa-chevron-down"></i>
          </div>
        </div>
        <div class="input-wrap"> 
          <div class="input">
            <label for="select-children">Under 13s</label>
            <select  v-model="children" name="children" id="select-children" :required="true" @change="setinfants">
              <option value="0">0</option>
              <option v-for="m in parseInt(child_options)" :key="m" :value="m">{{m}}</option>
            </select>
            <i class="fas fa-chevron-down"></i>
          </div>
        </div>
         <div v-if="show_infants" class="input-wrap"> 
          <div class="input">
            <label for="select-infants">Infants under 2</label>
            <select v-model="infants" name="infants" id="select-infants" :value="`Add Infants`">
              <option v-for="o in parseInt(infant_options)" :key="o" :value="o">{{o}}</option>
            </select>
            <i class="fa fa-plus" aria-hidden="true"></i>
          </div>
        </div>
       <div v-if="!show_venue && !show_infants" class="button-spacer"></div>
        <div class="input-wrap">
          <div class="button">
            <button type="submit" :disabled="submitStatus === 'PENDING'">Book a Test</button>
          </div>
        </div>
      </div>

      </form>
    </div>

 </div>
  <Footer></Footer>
</template>

<script>
import { required, between } from '@vuelidate/validators';
const axios = require('axios');
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
import Basket from "../../components/Basket.vue";
import Calendar from 'primevue/calendar';
import ('primevue/resources/themes/saga-blue/theme.css');
import ('primevue/resources/primevue.min.css');
import ('primeicons/primeicons.css');

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Basket,
    Calendar
  },
  data() {
    return {
      show_venue: true,
      show_infants: false,
      date_value: null,       
      venue:'',
      min_date_value: new Date(),
      adult_options:6,
      child_options:6,
      infant_options:6,
      adults:1,
      children:0,
      infants:0,
      touchUI: false,
      submitStatus: null,
      venues:[],
      venue_rules:[],
      window: {
        width: 0,
        height: 0
      }
    }
  },
  computed: {
    dateFormatter() {
      let dt = new Date(this.date_value);
      let newformat = '';
      if (dt) {
        newformat = (dt.getDate() % 10 == 1 && dt.getDate() != 11 ? 'st' : (dt.getDate() % 10 == 2 && dt.getDate() != 12 ? 'nd' : (dt.getDate() % 10 == 3 && dt.getDate() != 13 ? 'rd' : 'th'))); 
      }
      return newformat;
    }
  },
       validations() {
    return {
    date_value: {required},
    venue: {required},
    adults: {between: between(1, 6)},
    }
    },
  methods: {
    formatDateymd(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      return [year, month, day].join('/');
    },
    setwidget()
    {
      let venue_selected = this.venue;
      this.adult_options=0;
      this.child_options=0;
      this.infant_options=0;
      this.adults=1;
      this.children=0;
      this.infants=0;
      console.log(this.venue_rules);
      if (typeof this.venue_rules === "undefined") {
         this.adult_options=1;
         this.child_options=1;
      }
      else
      {
        for (const [key, value] of Object.entries(this.venue_rules)) {
            //console.log(key);
            if(value.venue_id==venue_selected && value.label=='booking_max_pax')
            {
              this.adult_options=parseInt(value.value);
              this.child_options=parseInt(value.value)-1;
              this.infant_options=parseInt(value.value)-1;
            }
            if(value.venue_id==venue_selected && value.label=='advance_booking_days')
            {
             this.min_date_value = this.adddays(parseInt(value.value));
            }
            if(value.venue_id==venue_selected && value.label=='time_slot_sync')
            {
            //
            }          
        }
      }
    },
    setchildren()
    {
      let adults_selected = this.adults;
      this.child_options=this.adult_options - parseInt(adults_selected);
    },
    setinfants()
    {
      let children_selected = this.children;
      let adults_selected = this.adults;
      this.infant_options=this.adult_options - parseInt(adults_selected)  - parseInt(children_selected);      
    },
    adddays (days) {
    var date = new Date();
    date.setDate(date.getDate() + days);
    return date;
},
    gotoPage(url) {
        window.location.href = url;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width > 768) {
          this.touchUI = false;
      } else {
          this.touchUI = true;
      }
    },
    
    async submit() {
      //console.log('submit!')
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        let date_value_selected  = this.formatDateymd(this.date_value);
        let date_value_selected_formatted  = this.date_value;
        let adults_selected  = this.adults;
        let children_selected  = this.children;
        let infant_selected  = this.infants;
        let venue_selected  = this.venue;
        localStorage.date_value_selected = date_value_selected;
        localStorage.adults_selected =adults_selected;
        localStorage.children_selected = children_selected;
        localStorage.infant_selected = infant_selected;
        localStorage.venue_selected = venue_selected;
        localStorage.date_value_selected_formatted = date_value_selected_formatted;
        this.submitStatus = 'PENDING';
        setTimeout(() => {
          this.submitStatus = 'OK';
          this.gotoPage('/results.html');
        }, 500);
      }
    }    
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
    mounted() {
    this.min_date_value = this.adddays(1);  
    localStorage.clear(); 
    
      let data = {"brand_id":"SZG"};
      if(!this.$cookie.isCookieAvailable('guid_token'))
      {
      axios.post('https://devapi.rezcomm.com/sso/guest/uuid', data, 
			{headers: {secretid:'52250f4cf663e9156ce81c8d8af8e1bc',secretkey:'3748b250f09a15993ae53fa2933e7c73027ec6e43cc29e9e'}}).then(response => {
			let datasent = JSON.parse(response.request.response); 
			let uuid = datasent.data.uuid.toString();
			this.$cookie.setCookie('guid_token', uuid, {expire: 24 * 60 * 60,});
			});
      }
      let dataa = {"brand_id":"SZG"};
      axios.post('https://devapi.rezcomm.com/medical_screening/frontend/brand/initial_value', dataa, 
			{headers: {secretid:'43f12e979500c0d5be5f217a8b5e3649',secretkey:'3e255f40e8d22783ff96a84e870e331cb49918f0abbaa2af'}}).then(response => {
  			let branddatasent = JSON.parse(response.request.response);
  			//console.log(branddatasent);
  		  if(branddatasent.user_exists=='Y')
  		  {
  		    this.venues=branddatasent.venue;
  		    this.venue_rules = branddatasent.venue_rules;
  		  }
			});
    
  },
}
</script>
 
<style lang="scss">
@import "@/assets/sass/normalise.scss";
@import "@/assets/sass/bodyStyle.scss";
@import "@/assets/sass/calenderOverride.scss";
</style>

<style scoped lang="scss">

  $submit-button: #E30613;


  .covid-bg {
    background: #e8e4e1;
    @media screen and (min-width: 554px) {
      background:rgba(35, 108, 163, 0.5);
      background: linear-gradient(0deg,rgba(0, 0, 0,0.3),rgba(0, 0, 0, 0.3)),  url('~@/assets/images/cov19.jpg') ;
      background-size:cover;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: center;
    }
  }


  .covid-bg-mobile {
    @media screen and (max-width: 553px) {
      background: linear-gradient(0deg,rgba(0, 0, 0,0.3),rgba(0, 0, 0, 0.3)), url('~@/assets/images/cov19.jpg') ;
      background-size:contain;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: bottom;
       padding-top: 30px !important;
      padding-bottom: 90px !important;
    }
  }


.form-group--alert, .form-group--error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
}

.form-group--error label,  .form-group--error input, .form-group--error select, .form-group--error .input {
    color: #f04124!important;
    border-color: #f79483;
}
  .welcome-text {
    padding: 60px 55px 60px;
    @media screen and (max-width: 1280px) {
      padding: 60px 35px 60px;
    }
    @media screen and (max-width: 959px) {
      padding: 60px 25px 60px;
    }
    max-width: 1280px;
    margin: 0 auto;
    text-align: left;
    h1, h2 {
      margin: 0;
      color: #fff;
      text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
        font-weight: lighter;
    }
    h1 {
      font-size: 48px;
      margin-bottom: 0px;
    }
    h2 {
      margin-top: 10px;
      font-size: 24px;
    }
  }

  .welcome-widget {
    max-width: 1280px;
    margin: 0 auto;
    margin-bottom: -10px;
    background: #fff;
    @media screen and (max-width: 959px) {
        margin-bottom: 40px;
      }
    .inner-widget-notice {
      display: block;
      text-align: left;
      margin: 0 10px 0px;
      padding: 45px 50px 0;
      @media screen and (max-width: 1280px) {
        padding: 45px 25px 0;
        margin: 0 10px 0px;
      }
      @media screen and (max-width: 959px) {
        padding: 25px 15px 0px;
        margin: 0 10px 0px;
      }
      
      h1 {
        margin: 0px 0px 15px;
        color:#1C315C;
        line-height: 1.4;
        font-size: 24px;
      }
      p {
        margin: 0px;
        color: #666666;
        line-height: 1.6;
        font-size: 16px;
      } 
    }
    .inner {
      box-sizing: border-box;
      margin: 10px;
      padding: 15px 45px 45px;
      display: flex;
        //flex-wrap: wrap;
      justify-content: center;
      @media screen and (max-width: 1280px) {
        padding:25px 25px;
      }
      @media screen and (max-width: 959px) {
        flex-direction: row;
        flex-wrap: wrap;
        padding:15px 15px;
        justify-content: space-between;
      }
      @media screen and (max-width: 769px) {
        flex-direction: column;
        padding: 15px;
      }
      .button-spacer {
        flex: 1;
      }
      .input-wrap {
        min-height: 100px;
        margin: 6px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-basis: 1;
        @media screen and (min-width: 1280px) {
          // makes all same size
          flex-basis: 0;
          flex-grow: 1;
        }
        @media screen and (max-width: 959px) {
          flex-direction: column;
          width: calc(50% - 20px);
          margin: 10px;
        }

        @media screen and (max-width: 769px) {
          flex-direction: column;
          width: 100%;
          margin: 0px;
        }
        .input {
          border: 1px solid #ddd;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 10px 10px 0 10px;
          position: relative;
          box-sizing: border-box;
          @media screen and (max-width: 959px) {
            padding: 10px 10px;
          }
          label {
            width: 100%;
            text-align: left;
            color: #666666;
            font-size: 15px;
            @media screen and (max-width: 1280px) {
              font-size: 14px;
            }
            @media screen and (max-width: 959px) {
              font-size: 14px;
            }
            @media screen and (max-width: 769px) {
              padding-top: 10px;
              font-size:  14px;
            }
          }
          span,
          select,
          input {
            cursor: pointer;
            width: 100%;
            height: 40px;
            margin: 0 25px;
            font-size: 18px;
            border: 0px solid #fff;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';
            &::-ms-expand {
              display: none;
            }
            @media screen and (max-width: 1280px) and  (min-width: 959px) {
              font-size: 15px;
            }
          }
          i {
            position: absolute;
            right: 15px;
            bottom: 28px;
            pointer-events: none;
          }
          
        }
        .button {
          width: 100%;
          height: 100%;
           @media screen and (max-width: 769px) {
            padding-top: 4px;
            }
          button {
            min-height: 100px;
            width: 100%;
            height: 100%;
            background: $submit-button;
            color: #fff;
            font-size: 24px;
            font-weight: bold;
            border: 0px solid #fff;
            border-radius: 3px;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            &:hover {
                background: darken($submit-button, 5%);
            }
          }
        }
      }
    }
    .widget-notice {
      text-align: left;
      color: #666666;
      justify-content: flex-start;
    }
  }




</style>
