<template>
    <div>
        <div class="footer-wrapper-top">
            <div class="footer-inner-top">
                <div class="footer-links">
                    <a href="/">HOME</a>
                    <a href="#">MANAGE MY BOOKING</a>
                    <a href="/faqs.html">FAQ</a>
                    <a href="/travel_with_confidence.html">TRAVEL WITH CONFIDENCE</a>
                    <a href="">TERMS & CONDITIONS</a>
                </div>
            </div>
         </div>  
         <div class="footer-wrapper-bottom"> 
             <div class="footer-inner-bottom">
                <span>Copyright © 2020 Your Airport Group. All rights reserved.</span>
                <span>Powered by Rezcomm</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer",
    };
</script>

<style scoped lang="scss">

     $dark-color: #164194;

    .footer-wrapper-top,
    .footer-wrapper-bottom {
        margin: 0;
        padding: 0;
        width: 100%;
        float: left;
        color: #fff;
    }

    .footer-wrapper-top {
        border-top: 1px solid #ddd;
        background-color: $dark-color;

    }

    .footer-wrapper-bottom {
        border-top: 1px solid #fff;
        background-color: $dark-color;
    }

    .footer-inner-top {
        max-width: 1280px;
        margin: 0 auto;
        display: flex;

        .footer-links {
            padding: 60px 0;
            @media screen and (max-width: 768px) {
                display: flex;
                flex-direction: column;
                a {
                    margin: 10px 0;
                }
            }
        }
        .footer-links a {
            color: #fff;
            text-decoration: none;
            padding: 0 30px 0 10px;
            font-size: 18px;
            &:hover {
                text-decoration: underline; 
            }
            @media screen and (max-width: 768px) {
                padding: 0 10px;
            }
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    .footer-inner-bottom {
        padding: 30px 10px;
        max-width: 1260px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        color: #fff;
        @media screen and (max-width: 768px) {
            flex-direction: column;
            padding: 30px 30px;
            span {
                padding: 20px 0;
            }
        }
    }
</style>